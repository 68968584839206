
  import {Component, Vue} from 'vue-property-decorator';
  import api from '@/infrastructure/api/API';
  import {progress} from '@/infrastructure/script/Progress';
  import {Content, ContentGetRequest, EmptyContent} from '@/types/material/Content';
  import {ProgressStatus} from '@/types/content/ProgressStatus';
  import MaterialProgress from '@/views/material/MaterialProgress.vue';
  import ContentVideoPlayer from '@/views/material/ContentVideoPlayer.vue';
  import {loginModule} from '@/stores/authentication/Account';
  import {LatestViewContentRegisterRequest} from '@/types/content/LatestViewContent';
  import {ViewContentRegisterRequest} from '@/types/content/ViewContent';
  import {ExerciseMaterialId} from '@/types/material/Material';

  @Component({
    components: {
      MaterialProgress,
      ContentVideoPlayer,
    },
  })
  export default class ContentDetail extends Vue {
    // data
    content: Content = EmptyContent;
    request: ContentGetRequest | null = null;

    // computed
    get isTestable(): boolean {
      return this.content.hasTest && (
        this.content.status === ProgressStatus.Testing ||
        this.content.status === ProgressStatus.Learned
      );
    }

    get isExercise(): boolean {
      if (!this.content.material) {
        return false;
      }
      return this.content.material.id === ExerciseMaterialId;
    }

    get isFastForwarding(): boolean {
      if (loginModule.isFastForwarding) {
        return true;
      }
      return this.content.status === ProgressStatus.Learned;
    }

    // methods
    async initialize(): Promise<void> {
      await this.getContent();
    }

    async getContent(): Promise<void> {
      const getContent = async (): Promise<void> => {
        if (!this.request) {
          return;
        }
        await api.getContent(this.request)
          .then((response: any) => {
            this.content = response;
          });
      };

      await progress(getContent);
    }

    async viewed(): Promise<void> {
      await this.viewedContent();
      this.toMyPage();
    }

    async viewedContent(): Promise<void> {
      const viewedContent = async (): Promise<void> => {
        const request = {
          contentId: this.content.id,
        } as ViewContentRegisterRequest;
        await api.registerViewContent(request)
          .catch((error: any) => {
            // TODO エラー
            if (error.data.errors) {
              return;
            }
          });
      };

      await progress(viewedContent);
    }

    async saveLatestViewContent(latestSeconds: number): Promise<void> {
      const request = {
        contentId: this.content.id,
        latestSeconds,
      } as LatestViewContentRegisterRequest;
      await api.registerLatestViewContent(request)
        .catch((error: any) => {
          // TODO エラー
          if (error.data.errors) {
            return;
          }
        });
    }

    toTest(): void {
      this.$router.push({path: `/content/${this.content.id}/test`}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    toMaterial(): void {
      this.$router.push({path: `/material/${this.content.material?.id}`}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    toMyPage(): void {
      this.$router.push({path: '/mypage'}).catch(() => {
        // @ts-ignore block is empty.
      });
    }

    // lifecycle hooks
    created(): void {
      this.request = {
        // @ts-ignore
        contentId: this.$route.params.id as number,
      };
      this.initialize();
    }
  }
