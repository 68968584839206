
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import videojs7 from 'vue-videojs7';
import {Content, DummySourceURL, EmptyContent} from '@/types/material/Content';

@Component({
  components: {
    VideoPlayer: videojs7.videoPlayer,
  },
})
export default class ContentVideoPlayer extends Vue {
  // prop
  @Prop({default: EmptyContent})
  content!: Content;
  @Prop({default: false})
  fastForward!: boolean;

  // data
  options: any = {
    autoplay: false, // ページを開いたときの自動再生を制御
    start: 0, // 開始時間
    controls: true,
    controlBar: {
      remainingTimeDisplay: false,
      playToggle: {},
      fullscreenToggle: {},
      volumeMenuButton: {
        inline: false,
        vertical: true,
      },
      timeDivider: true,
      durationDisplay: true,
      currentTimeDisplay: true,
    },
    sources: [],
    // language: 'ja',
    techOrder: this.isInternetExplorer ? ['flash'] : ['html5'],
  };
  timer: number = 0;
  currentTime: number = 0;
  // computed
  get player(): any {
    // @ts-ignore
    return this.$refs.hlsPlayer.player;
  }

  get isInternetExplorer(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    /* tslint:disable:max-line-length */
    return (userAgent.indexOf('msie') > -1) && (userAgent.indexOf('opera') === -1) || (userAgent.indexOf('trident/7') > -1);
  }

  // watch
  @Watch('content')
  onChangeContent() {
    this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
    const source = {
      withCredentials: true,
      type: 'application/x-mpegURL',
      src: this.content.sourceURL,
    };
    //// src:'/mock/hls/output.m3u8'; // URL-mock
    this.options.poster = this.content.thumbnailURL; // サムネイル
    this.options.sources.push(source);
  }

  @Watch('timer')
  onChangeTimer() {
    this.save();
  }
  // method
  playerStateChanged(event: any): void {

    if (event.loadeddata === true) {
      this.settingPlayerControls();
    }

    if (event.ended === true) {
      this.viewed(); // 動画再生完了
    }
    if (event.playing === true || event.pause === true) {
      this.currentTime = this.player.currentTime(); // 現在の動画再生時間
    }
  }

  settingPlayerControls() {
    if (!this.fastForward) {
      this.player.controlBar.progressControl.hide();
      this.addRewindButton();
    }
    this.options.poster = this.content.thumbnailURL; // サムネイル
    this.player.currentTime(this.content.latestTimeSeconds);
  }

  addRewindButton() {
    const button = this.player.controlBar.addChild('button');
    const dom = button.el();
    button.addClass('vjs-rewind-control');
    button.addClass('buttonClass');
    button.setAttribute('title', 'Rewind');
    button.enable();
    dom.innerHTML = '<span class="vjs-control-text">Rewind</span>';
    dom.onclick = this.rewind;
    dom.onMousedown = this.rewind;
    dom.onTouchstart = this.rewind;
  }

  rewind(event: any) {
    this.setCurrentTime(-15);
    event.preventDefault();
  }

  setCurrentTime(time: number) {
    this.player.currentTime(this.player.currentTime() + time);
  }

  @Emit('viewed')
  viewed(): void {
    return;
  }

  @Emit('save')
  save(): number {
    return this.currentTime;
  }

  // lifecycle hooks
  created(): void {
    setInterval(() => {
      this.timer++;
    }, 10000);
  }
}
